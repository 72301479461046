import axios from '@/libs/axios'

export default class Connections {
    
    index() {
        return axios.get(`/Conexiones/GetAllConnections`);
    }

    /**
     * Function to connections
     * @return Promise
     */
    getByStatus(status) {
        return axios.get(`/Conexiones/GetConnectionByStatus/${status}`);
    }

    testConnection(payload) {
        return axios.get(`/Conexiones/TestConnection`, payload);
    }
}
